<template>
  <div class="main">
    <div class="wxapp">
      <span class="tip">小程序/渠道:</span>
      <div class="content">
        <a-checkable-tag :checked="typeId == 0" @change="changeType(0)">全部</a-checkable-tag>
        <a-checkable-tag v-for="item in wxMiniData" :key="item.appId" :checked="item.appId == typeId" @change="changeType(item.appId)">{{item.back}}</a-checkable-tag>
      </div>
    </div>
    <a-divider dashed/>
    <a-row>
      <a-col :span="24">
        <a-space>
          <span>查询:</span>
          <a-input placeholder="按手机号搜索" style="width: 160px;" allowClear v-model="phone" />
          <a-input placeholder="按用户ID搜索" style="width: 160px;" allowClear v-model="id" />
          <a-input placeholder="按IP搜索" style="width: 160px;" allowClear v-model="ip" />
          <a-button type="primary" @click="getListData(1)" :loading="listLoading">搜索</a-button>
        </a-space>
      </a-col>
    </a-row>
    <!-- 菜单列表 -->
    <a-spin tip="Loading..." :spinning="listLoading">
      <a-table :columns="tableTitle" :data-source="data" :pagination="listPagination" size="middle" :rowKey="record=>record.id" style="margin-top:16px">
        <!-- <span slot="action" slot-scope="text, record">
          <a-button size="small" icon="gift" @click="showBoxOrderDrawer(record.id)">订单记录</a-button>
        </span> -->
      </a-table>
    </a-spin>
    <!-- 菜单列表 End -->
    <!-- 订单 -->
    <a-drawer width="540" placement="right" maskClosable :visible="boxDrawer" @close="hideDrawer" title="订单记录" destroyOnClose>
      <a-list :loading="drawerLoading" :data-source="drawerData" :pagination="{pageSize: 12, size: 'small'}">
        <a-list-item slot="renderItem" slot-scope="item">
          <span slot="actions" style="color:#f56a00;font-size: 14px">花费：19.90元</span>
          <a-list-item-meta :title="item.info" :description="'订单号：2051684555512'">
            <a-avatar shape="square" :size="50" slot="avatar" style="color: #ffffff; backgroundColor: #f56a00">欧阳旭白</a-avatar>
            <div slot="title" :href="item.href">
              {{ item.title }} 
              <a-tag v-if="item.type == 1" style="margin-left: 10px">在线起名</a-tag>
              <a-tag v-else color="#108ee9" style="margin-left: 10px">姓名评分</a-tag>
            </div>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </a-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableTitle: [
        { title: "ID", dataIndex: "id", key: "id" },
        { title: "小程序/渠道", dataIndex: "back", key: "back" },
        { title: "手机号", dataIndex: "phone", key: "phone" },
        { title: "注册时间", dataIndex: "create_time", key: "create_time"},
        { title: "最后访问时间", dataIndex: "last_login_time", key: "last_login_time"},
        { title: "最后访问IP", dataIndex: "last_login_ip", key: "last_login_ip"},
        { title: "最后访问IP地址", dataIndex: "last_ip_owner", key: "last_ip_owner"}
      ],
      typeId: 0,
      wxMiniData: [],
      data: [],
      drawerData: [1,2,3],
      listLoading: false, //列表loadding
      phone: '',
      id:'',
      ip:'',
      listPagination: {
        onChange: page => {
          this.getListData(page);
        },
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      drawerLoading: false,
      userDrawer: false,
      coinDrawer: false,
      boxDrawer: false
    };
  },
  mounted() {
    this.getMiniList()
    this.getListData(1);
  },
  methods: {
    getMiniList(){
      this.$http
        .get("/service/admin/getMiniList")
        .then(res => {
          //请求成功后的处理函数
          this.wxMiniData = res.data.result
        })
        .catch(() => {
          //请求失败后的处理函数
        });
    },
    getListData(page) {
      this.listLoading = true;
      this.$http
        .get("/service/user/list", {params: {
          page: page, 
          phone: this.phone, 
          id: this.id,
          ip: this.ip,
          app_id: this.typeId
        }})
        .then(res => {
          //请求成功后的处理函数
          this.listLoading = false;
          this.data = res.data.result.data;
          this.listPagination.total = res.data.result.count;
          this.listPagination.current = page;
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    },
    showBoxOrderDrawer(id){
      this.boxDrawer = this.drawerLoading = true
      this.$http
        .get("/service/user/userBoxOrder", {params: {id: id}})
        .then(res => {
          //请求成功后的处理函数
          this.drawerLoading = false;
          this.drawerData = res.data.result;
        })
        .catch(() => {
          //请求失败后的处理函数
          this.drawerLoading = false;
        });
    },
    hideDrawer(){
      this.drawerData = []
      this.userDrawer = this.coinDrawer = this.boxDrawer = false
    },
    //切换版块
    changeType(val){
      if(this.typeId === val)return false
      this.typeId = val
      this.listPage = 1
      this.getListData(1)
    },
  }
};
</script>

<style lang="scss" scope>
  .wxapp{
    display: flex;
    flex-direction: row;
    > .tip{
      margin-right: 8px;
    }
    > .content{
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > .ant-tag{
        margin-bottom: 4px;
      }
    }
  }
  .footer {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    text-align: right;
  }
  .ant-tree-child-tree-open li {
    display: inline-table;
  }
</style>
